import React from "react"
import { graphql } from "gatsby"

import PostsComponent from "../components/posts"
import Layout from "../components/layout"

export const query = graphql`
  query Category($id: Int!) {
    posts: allStrapiPost(filter: { categories : { elemMatch: { id: { eq: $id } } } }
      sort: {fields: [created_at] order: DESC}) {
      edges {
        node {
          strapiId
          slug
          title
          published
          card_image_description
          card_image {
            publicURL
          }
          categories {
              name
              slug
          }
        }
      }
    }
    category: strapiCategory(strapiId: { eq: $id }) {
      name
      slug
    }
  }
`

const Category = ({ data }) => {
  const posts = data.posts.edges
  const category = data.category

  return (
    <Layout title={category.name}>
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <h1 className="ui header">{category.name}</h1>
          <hr />
          <PostsComponent posts={posts} />
        </div>
      </div>
    </Layout>
  )
}

export default Category